<template>
  <div class="user-account" v-if="item.request">
    <div class="px-2">
      <GmapMap
        v-if="
          item.request.location &&
          item.request.location.lat &&
          item.request.location.long
        "
        :center="{
          lat: item.request.location.lat,
          lng: item.request.location.long
        }"
        :zoom="10"
        style="width: 100%; height: 200px"
      >
        <GmapMarker
          v-if="
            item.request.location &&
            item.request.location.lat &&
            item.request.location.long
          "
          :position="{
            lat: item.request.location.lat,
            lng: item.request.location.long
          }"
        />
      </GmapMap>
    </div>

    <div class="pt-4 px-5">
      <!-- details -->
      <div class="divider-dark mt-5"></div>
      <div class="d-flex align-center mt-3">
        <span class="black--text font-600 font-20 d-inline-block"
          >Location</span
        >
        <div class="lightblue px-4 py-2 radius-20 mx-5">
          <span class="white--text">{{ item.request.location.address }}</span>
        </div>
      </div>
      <div class="divider-dark mt-5"></div>
      <div class="d-flex align-center mt-3">
        <span class="black--text font-600 font-20 d-inline-block"
          >Requirments</span
        >
        <div
          class="lightblue px-4 py-2 radius-20 mx-5"
          v-for="(type, i) in item.request.experinceType"
          :key="i"
        >
          <span class="white--text">{{ type }}</span>
        </div>
      </div>
      <div class="mt-4">
        <span class="grey--text font-500 font-13 d-inline-block mr-3"
          >Description:</span
        >
        <span class="black--text font-500 font-13 d-inline-block mr-3">{{
          item.request.experinceTypeDescription
        }}</span>
      </div>
      <div class="divider-dark mt-5"></div>
      <v-row class="mt-4">
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Duration:</span
          >
          <span class="black--text font-500 font-13 d-inline-block"
            >{{ duration(item.request) }} Days</span
          >
        </v-col>
        <v-col cols="12" md="6" class="py-1" v-if="item.request">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Experience Guests:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.customer.family ? "Family + " : ""
          }}</span>
          <span class="black--text font-500 font-13 d-inline-block"
            >{{ item.request.numberOfPeople }} people</span
          >
        </v-col>
        <v-col cols="12" md="6" class="py-1" v-if="item.request">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Booked on:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.createdAt.slice(0, 10)
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1" v-if="item.request.startDate">
          <span class="grey--text font-500 font-13 d-inline-block mr-3">
            Start Date:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.startDate.slice(0, 10)
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Children:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.childrens ? "Yes" : "No"
          }}</span>
        </v-col>

        <v-col cols="12" md="6" class="py-1" v-if="item.request.endDate">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >End Date:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.endDate.slice(0, 10)
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Pets:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.pets ? "Yes" : "No"
          }}</span>
        </v-col>
        <v-col cols="12" md="6" class="py-1">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Transportation:</span
          >
          <span class="black--text font-500 font-13 d-inline-block">{{
            item.request.transportation ? "Yes" : "No"
          }}</span>
        </v-col>
        <v-col cols="12" md="6">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >Budget:</span
          >
          <span class="black--text font-500 font-13 d-inline-block"
            >{{ item.request.budget }}
          </span>
        </v-col>
      </v-row>
      <div class="divider-dark mt-5"></div>
      <v-row class="mt-4">
        <v-col cols="12">
          <span class="grey--text font-500 font-13 d-inline-block mr-3"
            >More Details:</span
          >
          <span class="black--text font-500 font-13 d-inline-block mr-3">{{
            item.request.notes
          }}</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loadingItem: false,
    avatar: require("@/assets/images/global/avatar.png")
  }),
  methods: {
    currentStatus(status) {
      if (status == 0) return "Request submitted";
    },

    duration(item) {
      var date1 = new Date(item.startDate.slice(0, 10));
      var date2 = new Date(item.endDate.slice(0, 10));
      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Difference_In_Days;
    }
  }
};
</script>

<style lang="scss" scoped>
.user-account {
  overflow: hidden;
  .user-search {
    .users {
      height: 100vh;
      overflow-y: scroll;
      background: #fbfbfd;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border: none !important;
    }
  }

  .b-left {
    border-left: 2px solid $grayicon;
    position: relative;
    left: -2px;
    height: calc(100vh - 70px);
    overflow-y: scroll;

    .account-avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .v-skeleton-loader__heading {
    width: 100%;
    margin: 0px !important;
  }
}
</style>
