<template>
  <div class="booking pl-5">
    <v-row class="w-100">
      <v-col
        v-if="!($vuetify.breakpoint.mobile && paramID)"
        class="bookings-list"
        cols="12"
        :md="!$vuetify.breakpoint.mobile && paramID ? 6 : 12"
      >
        <!-- table -->
        <div class="list-header">
          <div
            class="list-head d-flex justify-space-between align-center px-6 pt-5"
          >
            <span>
              <p class="gray--text mt-1">Custom Bookings ({{ total }})</p>
            </span>
            <div class="d-flex">
              <base-input
                :style="$vuetify.breakpoint.mobile ? { width: '100px' } : {}"
                placeholder="Search Bookings"
                @input="searchBooking"
                :rules="[]"
                v-model="searchWord"
              ></base-input>
            </div>
          </div>
        </div>
        <div v-if="loading">
          <v-data-table
            :headers="headers"
            :items="[{}, {}, {}, {}]"
            class="users-table"
            height="calc(100vh - 60px - 70px)"
            mobile-breakpoint="0"
            v-if="loading"
          >
            <template v-slot:item>
              <tr>
                <td v-for="(item, i) in headers" :key="i">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="bookings"
          item-key="_id"
          :item-class="
            (current) => {
              return paramID === current._id
                ? 'gray lighten-4 cursor-pointer'
                : 'cursor-pointer';
            }
          "
          class="users-table"
          height="calc(100vh - 60px - 70px)"
          :page.sync="page"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ 'items-per-page-options': [5, 15, 30, 50] }"
          :server-items-length="total"
          mobile-breakpoint="0"
          @click:row="viewItem"
          @update:page="fetchList"
          @update:items-per-page="fetchList"
          @update:sort-by="fetchList"
          @update:sort-desc="fetchList"
          fixed-header
        >
          <!-- name & email -->
          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <div class="mr-2 mt-2">
                <img
                  v-if="
                    item.guests && item.guests.user && item.guests.user.picture
                  "
                  class="user-avatar"
                  :src="item.guests.user.picture.url"
                  alt=""
                />
                <img v-else class="user-avatar" :src="avatar" alt="" />
              </div>
              <span
                class="d-block font-13 white-grey--text"
                v-if="item.guests"
                >{{ item.guests.user.name }}</span
              >
            </div>
          </template>

          <!-- status -->
          <template v-slot:item.status="{ item }">
            <span
              class="d-block font-13 font-500"
              :class="currentStatusColor(item.status)"
            >
              {{ currentStatusText(item.status) }}</span
            >
          </template>

          <template v-slot:item.createdAt="{ item }">
            <div class="my-2">
              <span
                class="d-block font-13 white-grey--text"
                v-if="item.createdAt"
                >{{ item.createdAt.slice(0, 10) }}</span
              >
            </div>
          </template>
        </v-data-table>
      </v-col>

      <!-- Details -->
      <v-col
        cols="12"
        :class="
          $vuetify.breakpoint.mobile
            ? 'maint-item pa-0 pt-10'
            : 'main-item pa-10 pr-5'
        "
        :md="6"
        v-if="paramID"
        :style="
          $vuetify.breakpoint.mobile ? {} : { borderLeft: '1px solid black' }
        "
      >
        <div
          class="cursor-pointer"
          :style="
            $vuetify.breakpoint.mobile
              ? {
                  position: 'absolute',
                  right: '10px',
                  top: '15px',
                  zIndex: '5'
                }
              : {
                  position: 'absolute',
                  left: 'calc(50% - 25px)'
                }
          "
          @click="collapse"
        >
          <v-icon size="40" color="black">mdi-progress-close</v-icon>
        </div>
        <v-tabs
          v-model="selectedTab"
          centered
          active-class="secondary--text"
          color="secondary"
          background-color="transparent"
        >
          <v-tab><span class="font-12">Request</span></v-tab>
          <v-tab><span class="font-12">Customer</span></v-tab>
          <v-tab><span class="font-12">Itinerary</span></v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab" class="mt-2 tabs-items">
          <v-tab-item class="tab-item">
            <request-section
              v-if="item.request && item.customer"
              :item="item"
            />
            <p v-else class="text-center">No Request Details</p>
          </v-tab-item>
          <v-tab-item>
            <user-details
              v-if="user"
              class="tab-item"
              :item="user"
              :viewOnly="true"
            />
            <p v-else class="text-center">No User Details</p>
          </v-tab-item>

          <v-tab-item class="tab-item">
            <itinerary
              v-if="item.itinerary"
              :item="item.itinerary"
              :itemText="item.details"
            />
            <p v-else class="text-center">No Itinerary Details</p>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserDetails from "@/components/User/UserDetails.vue";
import RequestSection from "./components/Request.vue";
import Itinerary from "./components/Itinerary.vue";

const SortEnum = {
  NAMEASC: 1,
  NAMEDESC: 2,
  PRICEASC: 3,
  PRICEDESC: 4,
  CREATEDATASC: 5,
  CREATEDATDESC: 6,
  LOCATIONASC: 7,
  LOCATIONDESC: 8,
  TYPEASC: 9,
  TYPEDESC: 10,
  init: function (sortBy, sortDesc) {
    const descSuffix = sortDesc ? "DESC" : "ASC";
    return this[`${sortBy.toUpperCase()}${descSuffix}`];
  }
};

export default {
  components: { UserDetails, RequestSection, Itinerary },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    paramID: "",
    headers: [
      { text: "Name", value: "name", width: "100px" },
      { text: "Price", value: "price", width: "100px" },
      { text: "Created At", value: "createdAt", width: "110px" },
      { text: "Location", value: "location", width: "200px" },
      { text: "Status", value: "status", width: "70px" },
      { text: "Type", value: "type", width: "100px" }
    ],
    bookings: [],
    total: 0,
    page: 1,
    keyUpdated: false,
    selectedTab: 0,
    item: undefined,
    selectedItem: {},
    sortBy: "createdAt",
    sortDesc: true,
    itemsPerPage: 15,
    searchWord: undefined,
    user: undefined,
    loading: true
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    currentStatusColor(status) {
      if (status == 0) return "yellow--text";
      if (status == 1) return "blue--text";
      if (status == 2) return "orange--text";
      if (status == 3) return "purple--text";
      if (status == 4) return "green--text";
      if (status == 5) return "red--text";
    },
    currentStatusText(status) {
      if (status == 0) return "Received";
      if (status == 1) return "Request sent to client";
      if (status == 2) return "Review requested from client";
      if (status == 3) return "Waiting for payment";
      if (status == 4) return "Paid";
      if (status == 5) return "Cancelled";
    },
    async searchBooking(searchWord) {
      this.bookings = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.bookings.length > 0) {
          this.paramID = this.bookings[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.item = undefined;
        }
      }
    },
    viewItem(item) {
      this.$router.push("/custom-bookings/" + item._id);
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(
          `custom-activity-request/${this.paramID}`
        );
        if (data) {
          this.item = data.data;
          if (this.item.customer) {
            this.fetchUser(this.item.customer._id);
          }
        }
      }
    },
    async fetchUser(userID) {
      if (userID) {
        let { data } = await this.$axios.get(`/users/${userID}`);

        if (data) {
          this.user = data.data;
        }
      } else {
        this.user = undefined;
      }
    },
    async fetchList() {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      const search =
        this.searchWord && this.searchWord.length > 0
          ? `&searchWord=${this.searchWord}`
          : "";
      const sort = this.sortBy
        ? `&sort=${SortEnum.init(this.sortBy, this.sortDesc)}`
        : "";
      var pageSize = this.itemsPerPage;
      if (this.itemsPerPage == -1) {
        pageSize = "";
      }
      let { data } = await this.$axios.get(
        `custom-activity-request?pageSize=${pageSize}&pageNumber=${this.page}${sort}${search}`
      );
      if (data) {
        this.bookings = data.data;
        this.total = data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    collapse() {
      this.$router.push("/custom-bookings");
      this.paramID = undefined;
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.booking {
  background: white;
  height: 100vh;
  overflow: hidden;
  .user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  .list-header {
    height: 70px;
  }
  .users-table {
    overflow: auto;
  }
  .tabs-items {
    overflow: hidden;
  }
  .tab-item {
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .user-details-details {
    overflow: auto;
    height: calc(100vh - 310px);
    padding-bottom: 20px;
  }
}
</style>
