<template>
  <div>
    <span
      class="main-title d-block my-2 black--text transition-3s font-20 font-500"
      >{{ itemText.title }}
    </span>
    <p class="gray--text font-14">
      {{ itemText.description }}
    </p>
    <v-row>
      <v-col cols="12">
        <span class="d-block black-grey--text font-500 font-16 mb-4 px-2"
          >NEED TO KNOW</span
        >
        <v-row class="px-2 mx-0">
          <v-col
            cols="12"
            md="6"
            class="py-1 pb-2 d-flex align-center justify-space-between"
          >
            <span class="grey--text font-500 font-14 d-inline-block mr-3"
              >Category</span
            >
            <span class="black--text font-500 font-14 d-inline-block">{{
              itemText.category
            }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-1 pb-2 d-flex align-center justify-space-between"
          >
            <span class="grey--text font-500 font-14 d-inline-block mr-3"
              >Distance</span
            >
            <span class="black--text font-500 font-14 d-inline-block"
              >distance</span
            >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-1 pb-2 d-flex align-center justify-space-between"
          >
            <span class="grey--text font-500 font-14 d-inline-block mr-3"
              >People</span
            >
            <span class="black--text font-500 font-14 d-inline-block">{{
              itemText.numberOfpeople
            }}</span>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-1 pb-2 d-flex align-center justify-space-between"
          >
            <span class="grey--text font-500 font-14 d-inline-block mr-3"
              >Date</span
            >
            <span
              class="black--text font-500 font-14 d-inline-block"
              v-if="itemText.date"
              >{{ itemText.date.slice(0, 10) }}</span
            >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="py-1 pb-2 d-flex align-center justify-space-between"
          >
            <span class="grey--text font-500 font-14 d-inline-block mr-3"
              >Total Stops</span
            >
            <span class="black--text font-500 font-14 d-inline-block">{{
              itemText.stops
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <div class="mb-5 mt-6">
          <GmapMap
            v-if="
              item[0].day.schedule[0] &&
              item[0].day.schedule[0].longitude &&
              item[0].day.schedule[0].latitude
            "
            :center="{
              lat: item[0].day.schedule[0].latitude,
              lng: item[0].day.schedule[0].longitude,
            }"
            :zoom="10"
            style="width: 100%; height: 300px"
          >
            <GmapMarker
              :key="index"
              v-for="(marker, index) in markers"
              :position="{ lat: marker.latitude, lng: marker.longitude }"
            />
          </GmapMap>
        </div>

        <v-row justify="center">
          <v-col cols="12" md="8">
            <div v-for="(day, i) in item" :key="i">
              <div
                class="d-flex align-center justify-space-between mb-8"
                v-for="(schedule, j) in day.day.schedule"
                :key="j"
              >
                <div class="w-100">
                  <div class="d-flex align-center justify-space-between">
                    <div class="d-flex align-center justify-space-between">
                      <div
                        class="step-min b-cover px-2 py-1 pb-2 radius-20 mr-2"
                      >
                        <v-icon color="secondary"
                          >mdi-{{ transportIcon(schedule.transport) }}</v-icon
                        >
                        <span class="black-text font-12 font-500"
                          >{{ schedule.duration }}min</span
                        >
                      </div>
                      <span class="secondary--text font-12 font-500">{{
                        schedule.location
                      }}</span>
                    </div>
                    <span class="black-grey--text font-12 font-500">{{
                      $moment(schedule.time).format("LT")
                    }}</span>
                  </div>

                  <div class="pl-7 mt-2">
                    <span class="font-20 font-500 d-block black--text">{{
                      schedule.title
                    }}</span>
                    <p class="font-12 white-grey--text">
                      {{ schedule.description }}
                    </p>
                  </div>
                </div>

                <!-- <div class="photos-section">
                  <div
                    class="photo mb-2 ml-5"
                    style="height: 120px; width: 100px"
                  >
                    <div
                      v-if="schedule && schedule.image[0]"
                      class="photo-count cursor-pointer"
                      @click="photosDialogHandler(schedule.image)"
                    >
                      <span class="white--text font-26"
                        >+{{ schedule.image.length }}</span
                      >
                    </div>
                    <img
                      v-if="
                        schedule.image[0] &&
                        schedule.image[0].mediaId
                      "
                      :src="schedule.image[0].mediaId.url"
                      alt=""
                    />
                  </div>
                </div> -->
              </div>
            </div>
          </v-col>
        </v-row>

        <v-dialog
          v-model="photosDialog"
          fullscreen
          hide-overlay
          transition="scale-transition"
          content-class="full-dialog"
        >
          <dialog-photos
            :key="photosDialog"
            :images="images"
            type="experience"
            @close="photosDialog = false"
          ></dialog-photos>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DialogPhotos,
  },
  data: () => ({
    photosDialog: false,
    images: [],
  }),
  computed: {
    markers() {
      let arr = [];
      this.item.forEach((day) => {
        day.day.schedule.forEach((sh) => {
          if (sh.latitude && sh.longitude) {
            arr.push(sh);
          }
        });
      });
      return arr;
    },
  },
  methods: {
    transportIcon(transport) {
      if (transport == "drive") {
        return "car";
      }
      if (transport == "walk") {
        return "walk";
      }
      if (transport == "swim") {
        return "swim";
      }
      if (transport == "eat") {
        return "walk";
      } else {
        return "blabla";
      }
    },
  },
};
</script>

<style></style>
