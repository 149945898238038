var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"booking pl-5"},[_c('v-row',{staticClass:"w-100"},[(!(_vm.$vuetify.breakpoint.mobile && _vm.paramID))?_c('v-col',{staticClass:"bookings-list",attrs:{"cols":"12","md":!_vm.$vuetify.breakpoint.mobile && _vm.paramID ? 6 : 12}},[_c('div',{staticClass:"list-header"},[_c('div',{staticClass:"list-head d-flex justify-space-between align-center px-6 pt-5"},[_c('span',[_c('p',{staticClass:"gray--text mt-1"},[_vm._v("Custom Bookings ("+_vm._s(_vm.total)+")")])]),_c('div',{staticClass:"d-flex"},[_c('base-input',{style:(_vm.$vuetify.breakpoint.mobile ? { width: '100px' } : {}),attrs:{"placeholder":"Search Bookings","rules":[]},on:{"input":_vm.searchBooking},model:{value:(_vm.searchWord),callback:function ($$v) {_vm.searchWord=$$v},expression:"searchWord"}})],1)])]),(_vm.loading)?_c('div',[(_vm.loading)?_c('v-data-table',{staticClass:"users-table",attrs:{"headers":_vm.headers,"items":[{}, {}, {}, {}],"height":"calc(100vh - 60px - 70px)","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function(){return [_c('tr',_vm._l((_vm.headers),function(item,i){return _c('td',{key:i},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)}),0)]},proxy:true}],null,false,2986918849)}):_vm._e()],1):_vm._e(),(!_vm.loading)?_c('v-data-table',{staticClass:"users-table",attrs:{"headers":_vm.headers,"items":_vm.bookings,"item-key":"_id","item-class":(current) => {
            return _vm.paramID === current._id
              ? 'gray lighten-4 cursor-pointer'
              : 'cursor-pointer';
          },"height":"calc(100vh - 60px - 70px)","page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":_vm.itemsPerPage,"footer-props":{ 'items-per-page-options': [5, 15, 30, 50] },"server-items-length":_vm.total,"mobile-breakpoint":"0","fixed-header":""},on:{"update:page":[function($event){_vm.page=$event},_vm.fetchList],"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":[function($event){_vm.sortBy=$event},_vm.fetchList],"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":[function($event){_vm.sortDesc=$event},_vm.fetchList],"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.fetchList],"click:row":_vm.viewItem},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"mr-2 mt-2"},[(
                  item.guests && item.guests.user && item.guests.user.picture
                )?_c('img',{staticClass:"user-avatar",attrs:{"src":item.guests.user.picture.url,"alt":""}}):_c('img',{staticClass:"user-avatar",attrs:{"src":_vm.avatar,"alt":""}})]),(item.guests)?_c('span',{staticClass:"d-block font-13 white-grey--text"},[_vm._v(_vm._s(item.guests.user.name))]):_vm._e()])]}},{key:"item.status",fn:function({ item }){return [_c('span',{staticClass:"d-block font-13 font-500",class:_vm.currentStatusColor(item.status)},[_vm._v(" "+_vm._s(_vm.currentStatusText(item.status)))])]}},{key:"item.createdAt",fn:function({ item }){return [_c('div',{staticClass:"my-2"},[(item.createdAt)?_c('span',{staticClass:"d-block font-13 white-grey--text"},[_vm._v(_vm._s(item.createdAt.slice(0, 10)))]):_vm._e()])]}}],null,false,811678830)}):_vm._e()],1):_vm._e(),(_vm.paramID)?_c('v-col',{class:_vm.$vuetify.breakpoint.mobile
          ? 'maint-item pa-0 pt-10'
          : 'main-item pa-10 pr-5',style:(_vm.$vuetify.breakpoint.mobile ? {} : { borderLeft: '1px solid black' }),attrs:{"cols":"12","md":6}},[_c('div',{staticClass:"cursor-pointer",style:(_vm.$vuetify.breakpoint.mobile
            ? {
                position: 'absolute',
                right: '10px',
                top: '15px',
                zIndex: '5'
              }
            : {
                position: 'absolute',
                left: 'calc(50% - 25px)'
              }),on:{"click":_vm.collapse}},[_c('v-icon',{attrs:{"size":"40","color":"black"}},[_vm._v("mdi-progress-close")])],1),_c('v-tabs',{attrs:{"centered":"","active-class":"secondary--text","color":"secondary","background-color":"transparent"},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',[_c('span',{staticClass:"font-12"},[_vm._v("Request")])]),_c('v-tab',[_c('span',{staticClass:"font-12"},[_vm._v("Customer")])]),_c('v-tab',[_c('span',{staticClass:"font-12"},[_vm._v("Itinerary")])])],1),_c('v-tabs-items',{staticClass:"mt-2 tabs-items",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{staticClass:"tab-item"},[(_vm.item.request && _vm.item.customer)?_c('request-section',{attrs:{"item":_vm.item}}):_c('p',{staticClass:"text-center"},[_vm._v("No Request Details")])],1),_c('v-tab-item',[(_vm.user)?_c('user-details',{staticClass:"tab-item",attrs:{"item":_vm.user,"viewOnly":true}}):_c('p',{staticClass:"text-center"},[_vm._v("No User Details")])],1),_c('v-tab-item',{staticClass:"tab-item"},[(_vm.item.itinerary)?_c('itinerary',{attrs:{"item":_vm.item.itinerary,"itemText":_vm.item.details}}):_c('p',{staticClass:"text-center"},[_vm._v("No Itinerary Details")])],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }